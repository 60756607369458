import i18n from "i18next";
import BrowserLanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(BrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        "navigator",
        "querystring",
        "subdomain",
        "cookie",
        "localStorage",
        "sessionStorage",
        "htmlTag",
        "path",
      ],
    },
    fallbackLng: "en",
    debug: true,
    ns: ["common", "preInterview", "reusables", "error", "formErrors"],
    defaultNS: "common",
    nsSeparator: ":",
    supportedLngs: ["en", "sv", "es", "de", "fr", "da", "fi", "it", "no", "nl", "pt", "tr", "el"],
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
